<template>
  <div
    class="manager-container"
    v-loading="isLoading"
    element-loading-spinner="el-icon-loading"
  >
    <template >
      <h1 class="div-main-view-title">Pricing And Formula</h1>
      <el-row class="handler-bar" :glutter="25" type="flex" justify="space-between" align="middle">
        <el-col :span="12">
          <el-tabs v-model="activeName" @tab-click="handleTabClick" >
            <el-tab-pane
              v-for="(item, index) in priceMethodTab"
              :key="index"
              :label="item.label"
              :name="item.value">
            </el-tab-pane>
          </el-tabs>
        </el-col>
        <el-col :span="12">
          <div class="button-block">
            <div class="query-item">
              <el-button
                v-if="hasPermission($permissionFunctionCode.EDIT)"
                round

                type="primary"
                @click="saveClick">
                {{ $t('save') }}
              </el-button>
            </div>
            <div class="query-item">
              <el-button
                v-if="hasPermission($permissionFunctionCode.ADD)"
                round
                type="outlined"
                @click="manageClick">
                {{ $t('manage') }}
              </el-button>
            </div>
          </div>
        </el-col>

      </el-row>
      <div class="table-block ">
        <div style="display:flex; " >
          <monthly-date @update="onChildUpdate" :holiday.sync="holiday" />
          <div style="display:inline-block;height: 100%;overflow-y: scroll; white-space: nowrap;">
            <price-methodology
              :ref="method.methodologyName"
              :key="index"
              :holiday.sync="holiday"
              v-for="(method, index) in methodologies"
              v-bind.sync="method"
              :year.sync="year"
              :month.sync="month"
              @setIsLoading="setIsLoading"
            />
          </div>
        </div>
      </div>
      <manage-methodology
        v-bind.sync="manageDialog"
        :type.sync="activeName"
        :priceMethodList.sync="methodologies"
      />
    </template>

  </div>
</template>

<script>
import MonthlyDate from '@/views/priceFormula/components/MonthlyDate'
import PriceMethodology from '@/views/priceFormula/components/priceMethodology'
import ManageMethodology from '@/views/priceFormula/components/manageMethodology'
import { ORDER_PRICE_RATE_TYPE_OPTIONS } from '@/constants.js'
import { mapState } from 'vuex'
import moment from 'moment'

export default {
  name: 'priceFormula',
  components: { ManageMethodology, PriceMethodology, MonthlyDate },
  data () {
    return {
      activeName: ORDER_PRICE_RATE_TYPE_OPTIONS[0].value,
      methodologies: [],
      manageDialog: {
        visible: false,
        title: 'Manage Price Methodology'
      },
      month: '',
      year: '',
      holiday: [],
      isLoading: false
    }
  },
  methods: {
    setIsLoading (val) {
      this.isLoading = val
    },
    getHoliday () {
      this.setIsLoading(true)
      // const month = moment(`${this.month} ${this.year}`).format('M')
      // const url = `${this.$apis.getCalendarByYear}/${this.year}/months/${month}`
      const url = `${this.$apis.getCalendarByYear}/${this.year}`
      this.$request.get({
        url
      }).then(res => {
        if (res.code === 1000) {
          this.holiday = this.processDate(res.data)
        }
        this.setIsLoading(false)
      })
    },
    handleTabClick () {
      this.getMethod()
    },
    manageClick () {
      this.manageDialog.type = this.activeName
      this.manageDialog.visible = true
    },
    saveClick () {
      this.setIsLoading(true)
      this.priceMethodList.map(item => {
        this.$refs[item][0].save()
        return item
      })
    },
    onChildUpdate ({ month, year }) {
      this.month = month
      this.year = year
    },
    getMethod () {
      this.setIsLoading(true)
      const url = `${this.$apis.getMethodologyByCompanyId}/${this.currentCompany.id}/bunkerwireType/${this.activeName}`
      this.$request.get({
        url
      }).then(res => {

        if(res.code===1000){
          if(res.data){
            this.methodologies = res.data
          }else{
            this.methodologies = []
          }
        }
        this.setIsLoading(false)
      })
    },

    processDate(calendar) {
      Array.from(calendar).forEach(d => {
        d.createdAt = new Date(d.createdAt)
        d.updatedAt = new Date(d.updatedAt)
        d.calendarDate = new Date(d.calendarDate)
      });
      return calendar;
    }
  },
  mounted () {
    this.getMethod()
    this.getHoliday()
  },
  computed: {
    ...mapState({
      currentCompany: 'currentCompany'
    }),
    priceMethodTab () {
      return ORDER_PRICE_RATE_TYPE_OPTIONS
    },
    priceMethodList () {
      const tempList = []
      this.methodologies.map(x => {
        tempList.push(x.methodologyName)
        return x
      })
      return tempList
    }
  },
  watch: {
    methodologies (newState, oldState) {

    },
    activeName (newState, oldState) {

    }
  }
}
</script>

<style lang="scss" scoped>
.manager-container {
  height: calc(100% - 5px);
  width: 100%;
  overflow: auto;
  // overflow-y: hidden;
  /deep/ .el-loading-spinner i {
    font-size: 30px;
  }
  .handler-bar {
    /deep/ .el-tabs__nav-wrap::after {
      background-color: transparent;
    }

    /deep/ .el-tabs__nav-next, /deep/ .el-tabs__nav-prev {
      font-size: 16px;
      // color: #0f376a;
    }
  }

  // /deep/ .el-tabs__item {
  //   font-size: 18px;
  //   color: #606266;
  //   font-weight: 400;

  //   &.is-active {
  //     font-weight: 600;
  //   }
  // }

  .table-block {
    width: 100%;
    height: calc(100% -166px) ;
    margin-bottom: 10px;
    //margin-top: 10px;
    border-radius: 10px;
    overflow:auto;
    box-shadow: 0 0 5px #eaeaea;
  }

  .button-block {
    @include flex-center-row;
    justify-content: flex-end;
    box-sizing: border-box;
    width: 100%;
    margin-bottom: 10px;

    .query-item {
      display: inline-flex;
      align-items: center;
      margin: 10px 0 10px 15px;

      span {
        display: inline-block;
        margin-right: 10px;
        flex: 5em;
      }
    }

    .button-item {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }

}
</style>
